import React, { useState } from "react"
import { useForm } from "react-hook-form"
import * as PopupStyles from "../styles/emailVerificationPopup.module.scss"

const EmailVerificationPopup = ({ verify }) => {
  const [leadInfo, setLeadInfo] = useState({
    email: "",
  })
  const { handleSubmit, errors, register } = useForm()

  const handleInputChange = e => {
    const key = e.target.name
    const value = e.target.value
    setLeadInfo({
      ...leadInfo,
      [key]: value,
    })
  }

  const showError = _fieldName => {
    {
      let fieldError = errors[_fieldName]
      if (!fieldError) return
      return (
        fieldError && (
          <div
            style={{
              color: "red",
              padding: 5,
              paddingLeft: 12,
              fontSize: "smaller",
            }}
          >
            {fieldError.message}
          </div>
        )
      )
    }
  }

  const verifyEmail = () => {
    verify(leadInfo.email)
  }

  return (
    <div className={PopupStyles.popupContainer}>
      <div className={PopupStyles.popupContent}>
        <p className={PopupStyles.headerText}>
          Please Enter Your Email To Verify
        </p>
        <form
          className={PopupStyles.popupForm}
          onSubmit={handleSubmit(verifyEmail)}
        >
          <div className={PopupStyles.inputContainer}>
            <label>Email</label>
            <input
              name="email"
              onChange={handleInputChange}
              value={leadInfo.email}
              type="text"
              ref={register({
                required: {
                  value: true,
                  message: "Please Enter Your Email",
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid Email Address",
                },
              })}
            />
            {showError("email")}
          </div>
          <input
            className={PopupStyles.submitButton}
            type="submit"
            name="submit"
            value="Verify"
          />
        </form>
      </div>
    </div>
  )
}

export default EmailVerificationPopup
