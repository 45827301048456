import Layout from "../components/layout"
import BookingForm from "../components/booking-form"
import React, { useEffect, useState } from "react"
import SEO from "../components/seo"
import * as RequestAQuoteStyles from "../styles/requestaquote.module.scss"
import firebase from "gatsby-plugin-firebase"
import Calendar from "../components/calendar"
import Loader from "../components/loader"
import EmailVerificationPopup from "../components/emailVerificationPopup"
import OneScreenBookingForm from "../components/oneScreenBookingForm"

const RequestQuotePage = ({ location }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [estimateState, setEstimateState] = useState({
    isEstimateScheduled: false,
    scheduleEstimateError: null,
    showSpinner: false,
    isRescheduling: false,
    scheduledEstimates: [],
    showVerificationPopup: false,
  })
  const toggleSpinner = () => {
    setEstimateState({
      ...estimateState,
      showSpinner: !estimateState.showSpinner,
    })
  }

  const getScheduledEstimates = () => {
    fetch(`${process.env.GATSBY_ZONUM_API_URL}/estimate-calendar`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ARN:
          " arn:aws:execute-api:us-east-1:074778375650:qgdj8hao2d/*/GET/estimate-calendar",
        Auth: "NONE",
      },
    })
      .then(res => res.json())
      .then(calendarData => {
        let calendarEvents = [
          ...calendarData.calendarEvents.scheduled_estimates,
          ...calendarData.calendarEvents.out_of_office,
        ]
        if (calendarEvents) {
          let estimateTimes = calendarEvents.map(
            x => new Date(Date.parse(x.start.dateTime))
          )

          estimateTimes.push(
            ...calendarEvents.map(x => new Date(Date.parse(x.end.dateTime)))
          )

          calendarEvents.forEach(estimate => {
            const start = estimate.start.dateTime
              ? new Date(estimate.start.dateTime)
              : new Date(`${estimate.start.date}T00:00:00`)
            const end = estimate.end.dateTime
              ? new Date(estimate.end.dateTime)
              : new Date(`${estimate.end.date}T00:00:00`)
            let timeBetween = new Date(start.getTime() + 60 * 60000)
            while (timeBetween < end) {
              if (!estimateTimes.includes(timeBetween))
                estimateTimes.push(timeBetween)
              timeBetween = new Date(timeBetween.getTime() + 60 * 60000)
            }
            let slotBefore = new Date(start.getTime() - 60 * 60000)
            let slotAfter = new Date(end.getTime() + 60 * 60000)
            estimateTimes.push(slotBefore)
            estimateTimes.push(slotAfter)
          })

          setEstimateState({
            ...estimateState,
            scheduledEstimates: estimateTimes,
            showSpinner: false,
          })
        }
      })
      .catch(e => {
        setEstimateState({ ...estimateState, showSpinner: false })
      })
  }

  useEffect(() => {
    if (!window || !window.localStorage) return
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      let infoForSignIn = window.localStorage.getItem("infoForSignIn")
      if (!infoForSignIn) {
        setEstimateState({ ...estimateState, showVerificationPopup: true })
        return
      }
      let userInfo = JSON.parse(infoForSignIn)?.userInfo || null
      if (!userInfo || !userInfo.email) {
        setEstimateState({ ...estimateState, showVerificationPopup: true })
        return
      }
      toggleSpinner()
      firebase
        .auth()
        .signInWithEmailLink(userInfo.email, window.location.href)
        .then(firebaseResult => {
          let client_id = new URLSearchParams(window.location.search).get("id")
          let project_id = new URLSearchParams(window.location.search).get(
            "project_id"
          )

          fetch(
            `${process.env.GATSBY_ZONUM_API_URL}/check-estimate?firebase_id=${firebaseResult.user.uid}&client_id=${client_id}&project_id=${project_id}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                ARN:
                  " arn:aws:execute-api:us-east-1:074778375650:qgdj8hao2d/*/GET/check-estimate",
                Auth: "NONE",
              },
            }
          )
            .then(estimateResult => {
              if (estimateResult.status === 200) {
                estimateResult.json().then(jsonEstimateResult => {
                  setEstimateState({ ...estimateState, showSpinner: false })
                  let estimateDate =
                    JSON.parse(jsonEstimateResult)?.scheduled_estimate_date ??
                    null
                  if (!estimateDate || new Date(estimateDate) < new Date())
                    return
                  setCurrentUser({
                    firebase_id: firebaseResult.user.uid,
                    client_id: client_id,
                    name: userInfo.name,
                    email: userInfo.email,
                    has_estimate_scheduled: JSON.parse(jsonEstimateResult)
                      .has_estimate_scheduled,
                    scheduled_estimate_date:
                      JSON.parse(jsonEstimateResult)?.scheduled_estimate_date ??
                      null,
                  })

                  // getScheduledEstimates()
                })
              }
            })
            .catch(estimateError => {
              // setEstimateState({ ...estimateState, showSpinner: false })
              // Assume lead doesn't have estimate scheduled
              setCurrentUser({
                firebase_id: firebaseResult.user.uid,
                client_id: client_id,
                name: userInfo.name,
                email: userInfo.email,
                has_estimate_scheduled: false,
                scheduled_estimate_date: null,
              })
              // getScheduledEstimates()
            })
        })
        .catch(authError => {
          if (authError.code && authError.code === "auth/invalid-action-code") {
            fetch(
              `${
                process.env.GATSBY_ZONUM_API_URL
              }/refresh-link?client_id=${new URLSearchParams(
                window.location.search
              ).get("id")}&project_id=${new URLSearchParams(
                window.location.search
              ).get("project_id")}`,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  ARN:
                    " arn:aws:execute-api:us-east-1:074778375650:qgdj8hao2d/*/GET/refresh-link",
                  Auth: "NONE",
                },
              }
            )
              .then(refreshLinkResult => {
                if (refreshLinkResult.status === 200) {
                  refreshLinkResult
                    .json()
                    .then(
                      refreshLinkJsonResult =>
                        (window.location.href = JSON.parse(
                          refreshLinkJsonResult
                        )?.new_link)
                    )
                }
              })
              .catch(refreshLinkError => {
                toggleSpinner()
              })
          } else {
            setEstimateState({ ...estimateState, showSpinner: false })
          }
        })
    } else {
      getScheduledEstimates()
    }
  }, [estimateState.showVerificationPopup])

  const shortLeadInfo =
    location && location.state
      ? {
          name: location.state.name || "",
          phone_number: location.state.phone_number || "",
          email: location.state.email || "",
        }
      : null

  const submitEstimateBooking = dateSelection => {
    toggleSpinner()
    fetch(`${process.env.GATSBY_ZONUM_API_URL}/schedule-estimate`, {
      method: "POST",
      body: JSON.stringify({
        lead_id: currentUser.lead_id,
        estimate_date: dateSelection,
        firebase_id: currentUser.firebase_id,
        has_estimate_scheduled: currentUser.has_estimate_scheduled,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ARN:
          " arn:aws:execute-api:us-east-1:074778375650:qgdj8hao2d/*/POST/schedule-estimate",
        Auth: "NONE",
      },
    })
      .then(scheduleEstimateResult => {
        if (scheduleEstimateResult.status === 200) {
          setEstimateState({
            ...estimateState,
            isEstimateScheduled: true,
            scheduleEstimateError: null,
          })
        } else {
          setEstimateState({
            ...estimateState,
            isEstimateScheduled: false,
            scheduleEstimateError:
              "Uh Oh! Something Went Wrong While Scheduling Your Estimate. Please Try Again or Contact Us At team@pivotpainters.com or (312) 854-9180",
            showSpinner: false,
          })
        }
      })
      .catch(scheduleEstimateError => {
        setEstimateState({
          ...estimateState,
          isEstimateScheduled: false,
          scheduleEstimateError:
            "Uh Oh! Something Went Wrong While Scheduling Your Estimate. Please Try Again or Contact Us At team@pivotpainters.com or (312) 854-9180",
          showSpinner: false,
        })
      })
  }

  const rescheduleEstimate = () => {
    setEstimateState({
      ...estimateState,
      isRescheduling: true,
    })
  }

  const verifyEmail = email => {
    window.localStorage.setItem(
      "infoForSignIn",
      JSON.stringify({ userInfo: { email: email } })
    )
    setEstimateState({ ...estimateState, showVerificationPopup: false })
  }

  return (
    <Layout hideMenu={true}>
      <SEO
        title="Request A Quote"
        description="Pivot Painters Chicago - Chicago Painting Service Quote"
      />

      <Loader activated={estimateState.showSpinner} />
      {!estimateState.scheduleEstimateError && currentUser === null && (
        <OneScreenBookingForm
          shortLeadInfo={shortLeadInfo}
          scheduledEvents={estimateState.scheduledEstimates}
        />
      )}
      {!estimateState.scheduleEstimateError &&
        currentUser !== null &&
        !estimateState.showVerificationPopup &&
        (!currentUser.has_estimate_scheduled ||
          estimateState.isRescheduling) && (
          <Calendar
            submitBooking={submitEstimateBooking}
            showConfirmationMessage={estimateState.isEstimateScheduled}
            toggleSpinner={() =>
              setEstimateState({
                ...estimateState,
                showSpinner: false,
              })
            }
            scheduledEstimates={estimateState.scheduledEstimates}
          />
        )}
      {!estimateState.scheduleEstimateError &&
        currentUser !== null &&
        currentUser.has_estimate_scheduled &&
        !estimateState.isRescheduling &&
        !estimateState.showVerificationPopup && (
          <div>
            {currentUser.scheduled_estimate_date !== null ? (
              <div className={RequestAQuoteStyles.appointmentDetailsContainer}>
                {currentUser.name && <h3>Hi {currentUser.name}, </h3>}

                <p className={RequestAQuoteStyles.appointmentParagraph}>
                  Your Appointment Details:
                </p>
                <p
                  className={`${RequestAQuoteStyles.appointmentParagraph} ${RequestAQuoteStyles.appointmentDateTimeContainer}`}
                >
                  {" "}
                  <span className={RequestAQuoteStyles.dateTime}>
                    {new Date(
                      currentUser.scheduled_estimate_date
                    ).toLocaleDateString()}{" "}
                  </span>
                  at{" "}
                  <span className={RequestAQuoteStyles.dateTime}>
                    {new Date(
                      currentUser.scheduled_estimate_date
                    ).toLocaleTimeString([], { timeStyle: "short" })}
                  </span>
                </p>
                <p>
                  * If you would like to reschedule or cancel your appointment,
                  please call us at <strong>(312) 854-9180</strong> or email us
                  at <strong>team@pivotpainters.com</strong>
                </p>
              </div>
            ) : (
              <p>
                Our Calendar Indicates You Have Previously Scheduled An Estimate
              </p>
            )}
          </div>
        )}

      {estimateState.showVerificationPopup && (
        <EmailVerificationPopup verify={verifyEmail} />
      )}
      {estimateState.scheduleEstimateError && (
        <div>
          <h2>{estimateState.scheduleEstimateError}</h2>
        </div>
      )}

      {/*
      <iframe src="https://pivotpainters.youcanbook.me/?noframe=true&skipHeaderFooter=true"  style={{ width: 100 + '%', height: 1000 + 'px', border: 0 + 'px', backGroundColor: 'transparent' }} frameborder="0" allowtransparency="true"></iframe>
      */}
    </Layout>
  )
}

export default RequestQuotePage
