import React from "react"
import * as LoaderStyles from "../styles/loader.module.scss"

const Loader = ({ activated }) => {
  return (
    <div
      className={
        activated
          ? LoaderStyles.overlay
          : `${LoaderStyles.overlay} ${LoaderStyles.hidden}`
      }
    >
      <div className={LoaderStyles.loader}></div>
    </div>
  )
}

export default Loader
